<template>
  <div>
    <TablaNegociaciones></TablaNegociaciones>
  </div>
</template>

<script>
import TablaNegociaciones from '@/components/comercial/TablaNegociaciones.vue';
export default {
  components: {
    TablaNegociaciones,
  },
};
</script>

<style></style>
