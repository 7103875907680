<template>
  <vx-card class="tabla-negociaciones">
    <vs-prompt
      class="tabla-negociaciones__prompt"
      :active.sync="activePromptStatus"
      title="Cambiar estatus negociación"
      @accept="guardarStatus"
      accept-text="Guardar"
      cancel-text="Descartar"
    >
      <vs-select
        class="w-full"
        placeholder="Seleccion un status"
        label="Status"
        v-model="status"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item, index) in estados"
        />
      </vs-select>
      <vs-textarea class="mt-4" label="Observación" v-model="observacion" />
    </vs-prompt>
    <!-- TABLE ACTION ROW -->
    <div class="flex justify-between items-center">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
      <div>
        <vs-button class="mb-4 md:mb-0">Añadir</vs-button>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="negociaciones"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererActions from './cell-renderer/CellRendererActions';
export default {
  components: {
    AgGridVue,
    CellRendererActions,
  },
  data() {
    return {
      negociacionSeleccionada: null,
      //VSPromptStatus
      activePromptStatus: false,
      status: null,
      observacion: '',
      estados: [],
      // Table
      searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Razón social',
          field: 'razonSocial',
          width: 175,
          filter: true,
        },
        {
          headerName: 'NIT',
          field: 'NIT',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Contacto',
          field: 'contacto',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Teléfono',
          field: 'telefono',
          filter: true,
          width: 170,
        },
        {
          headerName: 'Tipo de negocio',
          field: 'tipoNegocio',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Dirección',
          field: 'direccion',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Medios de entrega',
          field: 'medios',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Horario de atención',
          field: 'horarioAtencion',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Acciones',
          field: 'acciones',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      negociaciones: [],
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
        // this.gridOptions.columnApi.setColumnPinned('email', null);
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    changeStatus({ negociacion, observacion, status }) {
      this.status = status;
      this.negociacionSeleccionada = negociacion;
      this.observacion = observacion;
      this.activePromptStatus = true;
    },
    guardarStatus() {
      // Reactividad despues de haber hecho los cambios exitosamente en el servidor
      this.negociacionSeleccionada.status = this.status;
      this.gridApi.refreshCells();
      this.$vs.notify({
        color: 'success',
        title: 'Estado actualizado!',
        text: 'El estado de la negociación fue correctamente actualizado.',
      });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    fetchNegociaciones() {
      this.$http('/api/negociaciones').then(({ data }) => {
        this.negociaciones = data;
      });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.context = {
      componentParent: this,
    };
    this.fetchNegociaciones();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.tabla-negociaciones {
  &__prompt {
    .dialog-title {
      color: $nepal !important;
      font-weight: $font-weight-bold;
    }
  }
}
</style>
