<template>
  <div>
    <feather-icon
      icon="SettingsIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="configRecord"
    >
    </feather-icon>
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
    <feather-icon
      icon="TrashIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererEdit',
  methods: {
    editRecord() {
      this.$router
        .push(
          '/comercial/negociaciones/editar-negociacion/' + this.params.data.id
        )
        .catch(() => {});

      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose
              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
    configRecord() {
      this.params.context.componentParent.changeStatus({
        status: this.params.data.status,
        negociacion: this.params.data,
        observacion: `Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}, Value: ${this.params.data.status}`,
      });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Estás seguro de borrar "${this.params.data.razonSocial}"?`,
        accept: this.deleteRecord,
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    },
    deleteRecord() {
      this.showDeleteSuccess();
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Negociación eliminada',
        text: 'La negociación fue correctamente eliminada',
      });
    },
  },
};
</script>
